.temp {
	width:100%;
}


/* ********************************************  GENERAL  ********************************************  */

.deselect {
    -webkit-touch-callout: none;
    user-select: none;
}

.noedge {
	padding:0;
	margin:0;
}

.nolink {
	text-decoration: none;
}

.pointer {
	cursor: pointer;
}

/* ********************************************  DISPLAY  ********************************************  */

.absolute {
	position:absolute;
}

.relative {
	position:relative;
}

.fixed {
	position:fixed;
}

.right {
	float:right;
}

.left {
	float:left;
}

.clear {
	clear:both;
}

.clear-right {
	clear:right;
}

.clear-left {
	clear:left;
}

.block {
	display:block;
}

.none {
	display:none;
}

.box {
	box-sizing: border-box;
}

.inline {
	display:inline-block;
}

.table {
    display:table;
}

.table-cell {
    display:table-cell;
}


.flex {
	display:flex;
}

.flex-row {
	flex-direction: row;
}

.flex-column {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-wrap-reverse {
	flex-wrap:wrap-reverse;
}

.flex-justify-start {
	justify-content: flex-start;
}


.flex-justify-end {
	justify-content: flex-end;
}

.flex-justify-center {
	justify-content: center;
}

.flex-justify-space {
	justify-content: space-around;
}


.flex-justify-between {
	justify-content: space-between;
}

.flex-grow {
	flex:flex-grow;
}

.flex-shrink {
	flex:flex-shrink;
}

.flex-grow-1 {
	flex-grow:1;
}

.flex-grow-2 {
	flex-grow: 2;
}

.flex-grow-3 {
	flex-grow:3;
}

.flex-align-start {
	align-items: flex-start;
}

.flex-align-end {
	align-items:flex-end;
}

.flex-align-center {
	align-items:center;
}

.flex-center-y {
	align-content:center;
}

.flex-center-x {
	justify-content: center;
}








/* ********************************************  POSITIONING  ********************************************  */

.corner {
	top:0;
	left:0;
}

.fill {
	top:0;
	left:0;
	width:100%;
	height:100%;
}

.inset1 {
	width:98%;
	height:98%;
}

.inset2 {
	width:96%;
	height:96%;
}

.inset5 {
	width:90%;
	height:90%;
}

.inset10 {
	width:80%;
	height:80%;
}

.inset-mobile-1 {
	width:95%;
	height:97%;
}

.inset-mobile-2 {
	width:93%;
	height:95%;
}


/* _________________________________Right_______________________________ */


$i: 0;

@while $i <= 100 {
  .right#{$i} {
    right: #{$i + "%"};
  }

  $i: $i + 5;

}


$i: 0;

@while $i <= 100 {
  .right-#{$i} {
    right: #{$i}px;
  }

  $i: $i + 5;
}

$i: 200;

@while $i <= 500 {
  .right-#{$i} {
    right: #{$i}px;
  }

  $i: $i + 100;
}


$i: -200;

@while $i < 0 {
  .right-minus#{abs($i)} {
    right: #{$i + "%"};
  }

  $i: $i + 10;
}





$i: -500;

@while $i < -100 {
  .right-minus-#{abs($i)} {
    right: #{$i}px;
  }

  $i: $i + 50;
}



$i: -100;


@while $i < 0 {
  .right-minus-#{abs($i)} {
    right: #{$i}px;
  }

  $i: $i + 10;
}




/* _________________________________Left_______________________________ */


$i: 0;

@while $i <= 100 {
  .left#{$i} {
    left: #{$i + "%"};
  }

  $i: $i + 5;
}


$i: 0;

@while $i <= 100 {
  .left-#{$i} {
    left: #{$i}px;
  }

  $i: $i + 5;
}


$i: 200;


@while $i <= 500 {
  .left-#{$i} {
    left: #{$i}px;
  }

  $i: $i + 100;
}


$i: -100;

@while $i < 0 {
  .left-minus#{abs($i)} {
    left: #{$i + "%"};
  }

  $i: $i + 10;
}



$i: -200;

@while $i < 0 {
  .left-minus-#{abs($i)} {
    left: #{$i}px;
  }

  $i: $i + 10;
}





/* _________________________________Top_______________________________ */


$i: 0;

@while $i <= 100 {
  .top#{$i} {
    top: #{$i + "%"};
  }

  $i: $i + 5;
}


$i: 0;

@while $i <= 100 {
  .top-#{$i} {
    top: #{$i}px;
  }

  $i: $i + 5;
}

$i: 200;

@while $i <= 500 {
  .top-#{$i} {
    top: #{$i}px;
  }

  $i: $i + 100;
}


$i: -100;

@while $i < 0 {
  .top-minus#{abs($i)} {
    top: #{$i + "%"};
  }

  $i: $i + 10;
}



$i: -200;

@while $i < 0 {
  .top-minus-#{abs($i)} {
    top: #{$i}px;
  }

  $i: $i + 10;
}





/* _________________________________Bottom_______________________________ */


$i: 0;

@while $i <= 100 {
  .bottom#{$i} {
    bottom: #{$i + "%"};
  }

  $i: $i + 5;
}


$i: 0;

@while $i <= 100 {
  .bottom-#{$i} {
    bottom: #{$i}px;
  }

  $i: $i + 5;
}

$i: 200;

@while $i <= 500 {
  .bottom-#{$i} {
    bottom: #{$i}px;
  }

  $i: $i + 100;
}


$i: -100;

@while $i < 0 {
  .bottom-minus#{abs($i)} {
    bottom: #{$i + "%"};
  }

  $i: $i + 10;
}



$i: -200;

@while $i < 0 {
  .bottom-minus-#{abs($i)} {
    bottom: #{$i}px;
  }

  $i: $i + 10;
}



/* ______________________________Margin__________________________ */



.margin-auto {
	margin:auto;
}



$i: 0;

@while $i <= 10 {
  .margin#{$i} {
    margin: #{$i + "%"};
  }

  $i: $i + 1;
}



$i: 0;

@while $i <= 100 {
  .margin-#{$i} {
    margin: #{$i}px;
  }

  $i: $i + 5;
}



$i: 20;

@while $i <= 50 {
  .margin-top-#{$i} {
    margin-top: #{$i}px;
  }

  $i: $i + 10;
}






$i: 0;

@while $i < 100 {
  .margin-bottom-#{$i} {
    margin-bottom: #{$i}px;
  }

  $i: $i + 10;
}




$i: 100;

@while $i <= 500 {
  .margin-bottom-#{$i} {
    margin-bottom: #{$i}px;
  }

  $i: $i + 100;
}



.margin-right-5 {
    margin-right:5px;
}


$i: 10;

@while $i <= 100 {
  .margin-right-#{$i} {
    margin-right: #{$i}px;
  }

  $i: $i + 10;
}


.margin-left5 {
	margin-left:5%;
}

.margin-left-5 {
    margin-left:5px;
}


$i: 10;

@while $i <= 100 {
  .margin-left-#{$i} {
    margin-left: #{$i}px;
  }

  $i: $i + 10;
}



$i: -100;

@while $i >= 10 {
  .margin-left-minus#{abs($i)} {
    margin-left: #{$i + "%"};
  }

  $i: $i + 10;
}




$i: 5;

@while $i <= 50 {
  .margin-h#{$i} {
    margin:0 #{$i + "%"};
  }

  $i: $i + 5;
}




$i: 5;

@while $i <= 100 {
  .margin-h-#{$i} {
    margin:0 #{$i}px;
  }

  $i: $i + 5;
}



$i: 200;

@while $i <= 500 {
  .margin-h-#{$i} {
    margin:0 #{$i}px;
  }

  $i: $i + 100;
}



$i: -100;

@while $i >= 0 {
  .margin-h-minus-#{abs($i)} {
    margin:0 #{$i + "%"};
  }

  $i: $i + 10;
}



$i: 5;

@while $i <= 50 {
  .margin-v#{$i} {
    margin:#{$i + "%"} 0;
  }

  $i: $i + 5;
}




$i: 5;

@while $i <= 100 {
  .margin-v-#{$i} {
    margin:#{$i}px 0;
  }

  $i: $i + 5;
}



$i: 200;

@while $i <= 500 {
  .margin-v-#{$i} {
    margin:#{$i}px 0;
  }

  $i: $i + 100;
}



$i: -100;

@while $i >= 0 {
  .margin-v-minus-#{abs($i)} {
    margin:#{$i + "%"} 0;
  }

  $i: $i + 10;
}




.padding-auto {
	padding:auto;
}


.padding-left {
    padding:10px 10px 10px 0;

}

.padding-right {
    padding:10px 0 10px 10px;
}

.padding-up {
    padding:0 10px 10px;
}







$i: 0;

@while $i <= 10 {
  .padding#{$i} {
    padding: #{$i + "%"};
  }

  $i: $i + 1;
}



$i: 0;

@while $i <= 100 {
  .padding-#{$i} {
    padding: #{$i}px;
  }

  $i: $i + 5;
}



$i: 20;

@while $i <= 50 {
  .padding-top-#{$i} {
    padding-top: #{$i}px;
  }

  $i: $i + 10;
}



.padding-bottom-40 {
	padding-bottom:40px;
}

.padding-bottom-50 {
	padding-bottom:50px;
}




$i: 100;

@while $i <= 500 {
  .padding-bottom-#{$i} {
    padding-bottom: #{$i}px;
  }

  $i: $i + 100;
}



.padding-right-5 {
    padding-right:5 + "px";
}


$i: 10;

@while $i <= 100 {
  .padding-right-#{$i} {
    padding-right: #{$i}px;
  }

  $i: $i + 10;
}


.padding-left5 {
	padding-left:5%;
}

.padding-left-5 {
    padding-left:5px;
}


$i: 10;

@while $i <= 100 {
  .padding-left-#{$i} {
    padding-left: #{$i}px;
  }

  $i: $i + 10;
}



$i: -100;

@while $i >= 10 {
  .padding-left-minus#{abs($i)} {
    padding-left: #{$i + "%"};
  }

  $i: $i + 10;
}




$i: 5;

@while $i <= 50 {
  .padding-h#{$i} {
    padding:0 #{$i + "%"};
  }

  $i: $i + 5;
}




$i: 5;

@while $i <= 100 {
  .padding-h-#{$i} {
    padding:0 #{$i}px;
  }

  $i: $i + 5;
}



$i: 200;

@while $i <= 500 {
  .padding-h-#{$i} {
    padding:0 #{$i}px;
  }

  $i: $i + 100;
}



$i: -100;

@while $i >= 0 {
  .padding-h-minus-#{abs($i)} {
    padding:0 #{$i + "%"};
  }

  $i: $i + 10;
}



$i: 5;

@while $i <= 50 {
  .padding-v#{$i} {
    padding:#{$i + "%"} 0;
  }

  $i: $i + 5;
}




$i: 5;

@while $i <= 100 {
  .padding-v-#{$i} {
    padding:#{$i}px 0;
  }

  $i: $i + 5;
}



$i: 200;

@while $i <= 500 {
  .padding-v-#{$i} {
    padding:#{$i}px 0;
  }

  $i: $i + 100;
}



$i: -100;

@while $i >= 0 {
  .padding-v-minus-#{abs($i)} {
    padding:#{$i + "%"} 0;
  }

  $i: $i + 10;
}




/* _______________________________________________________Font____________________________________________ */







.font-typewriter {
	font-family: 'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace;
}

.font-bold{
	font-weight:bold;
}

.font-italic {
	font-style:italic;
}




$i: 5;

@while $i <= 100 {
  .font-#{$i} {
    font-size:#{$i}px;
  }

  $i: $i + 5;
}



$i: 200;

@while $i <= 500 {
  .font-#{$i} {
    font-size:#{$i}px;
  }

  $i: $i + 100;
}



$i: 0.1;



@while $i <= 5 {
	$name: "";
	$test: abs($i - floor($i));


	@if abs(0 - $test) <= 0.01 or $i == 1 or $i == 5 {
		$name: #{$i};
	} @else {
		$shift: 0;
	
		@if $i < 1 {
			$shift: 100;
		}
	
		$name: #{$i * 100 + $shift};
	}


  	.font#{$name}-rem {
    	font-size:#{$i}rem;
  	}

  	$i: $i + 0.1;
}



$i: 10;


@while $i <= 100 {

	.line-height-#{$i} {
		line-height:#{$i}px;
	}

	$i: $i + 10;
}




.rounded5 {
	border-radius:5px;
}




$i: 10;


@while $i <= 100 {

	.rounded#{$i} {
		border-radius:#{$i}px;
	}

	$i: $i + 10;
}



.circle {
   border-radius:50%;
}


$i: 10;


@while $i <= 100 {

	.rounded-top-#{$i} {
		border-top-left-radius:#{$i}px;
		border-top-right-radius:#{$i}px;
	}

	$i: $i + 10;
}



$i: 10;


@while $i <= 100 {

	.rounded-bottom-#{$i} {
		border-bottom-left-radius:#{$i}px;
		border-bottom-right-radius:#{$i}px;
	}

	$i: $i + 10;
}



$i: 10;


@while $i <= 100 {

	.rounded-left-#{$i} {
		border-top-left-radius:#{$i}px;
		border-bottom-left-radius:#{$i}px;
	}

	$i: $i + 10;
}





$i: 10;


@while $i <= 100 {

	.rounded-right-#{$i} {
		border-top-right-radius:#{$i}px;
		border-bottom-right-radius:#{$i}px;
	}

	$i: $i + 10;
}







.nooutline {
	outline:none;
	border:none;
}




.border0 {
	border:0;
}






$i: 5;


@while $i <= 50 {

	.border-#{$i} {
		border:#{$i}px solid black;
	}

	$i: $i + 5;
}









.border-width-20 {
	border-width:20px;
	border-style:solid;
}

.border-color-black {
	border-color:black;
}

.border-color-white {
	border-color:white;
}

.border-color-1-6 {
	border-color:#a0a15f;
}


.border-color-2-1 {
	border-color:#510000;
}

.border-color-2-2 {
	border-color:#002c55;
}

.border-color-2-3 {
	border-color:#135100;
}

.border-color-2-4 {
	border-color:#510026;
}

.border-color-2-5 {
	border-color:#004f5d;
}

.border-color-green6 {
	border-color:#12ff00;
}





.border {
	border:1px solid black;
}

.border-white {
	border:1px solid white;
}

.border-white-3 {
	border:3px solid white;
}

.border-red {
	border:1px solid red;
}

.border-blue {
	border:1px solid blue;
}

.border-green6 {
	border:1px solid #12ff00;
}




.border-top {
	border-top:1px solid black;
}

.border-left {
	border-left:1px solid black;
}

.border-right {
	border-right:1px solid black;
}

.border-bottom {
	border-bottom:1px solid black;
}



.border-top-blue {
	border-top:1px solid blue;
}



.border-top-trans {
	border-top:1px solid;
}

.border-left-trans {
	border-left:1px solid;
}

.border-right-trans {
	border-right:1px solid;
}

.border-bottom-trans {
	border-bottom:1px solid;
}






.border-top-white {
	border-top:1px solid white;
}

.border-bottom-white {
	border-bottom:1px solid white;
}

.border-right-white {
	border-right:1px solid white;
}

.border-left-white {
	border-left:1px solid white;
}






.lowered {
	box-shadow: 0 0 10px 0 #424242 inset;
}

.lowered-white {
	box-shadow: 0 0 10px 0 white inset;
}

.lowered-dark {
	box-shadow: 0 0 30px 0 #000 inset;
}

.raised {
	box-shadow: 0 0 10px 0 #424242;
}

.raised-white {
	box-shadow: 0 0 10px 0 #fff;
}

.raised-dark {
	box-shadow: 0 0 10px 0 #000;
}

.shadow {
	box-shadow: 5px 5px 10px 0 #424242;
}

.shadow-white {
	box-shadow: 5px 5px 10px 0 #fff;
}







$i: 0;


@while $i <= 100 {


	.opacity#{$i} {
		opacity:#{$i / 100}
	}

	$i: $i + 10;
}




.hidden {
	visibility:hidden;
}









.white {
	color:white;
}

.white-back {
	background-color:white;
}

.black {
	color:black;
}

.black-back {
	background-color:black;
}

.gray {
	color:#333;
}

.gray-back {
	background-color:#333;
}

.gray2 {
	color:#909090;
}

.gray2-back {
	background-color:#909090;
}

.blue {
	color:blue;
}

.blue-back {
	background-color:blue;
}

.blue2 {
	color:#006cff;
}

.blue2-back {
	background-color:#003;
}

.blue3 {
	color:#388bfd;
}

.blue3-back {
	background-color:#0d6591;
}

.blue4 {
	color:#00004d;
}

.blue4-back {
	background-color:#00004d;
}

.blue5 {
	color:#294675;
}

.blue5-back {
	background-color: #294675;
}

.green {
	color:#030;
}

.green-back {
	background-color:#030;
}

.green2 {
	color:#0da991;
}

.green2-back {
	background-color:#0da991;
}

.green3 {
	color:#00a40c;
}

.green3-back {
	background-color:#00a40c;
}

.green4 {
	color:#53ff53;
}

.green4-back {
	background-color:#53ff53;
}

.green5 {
	color:#3d7529;
}

.green5-back {
	background-color: #3d7529;
}

.green6 {
	color:#12ff00;
}

.green6-back {
	background-color:#12ff00;
}

.green7 {
	color:#297529;
}

.green7-back {
	background-color:#297529;
}

.red {
	color:red;
}

.red-back {
	background-color:red;
}

.red2 {
	color:#ff2121;
}

.red2-back {
	background-color:#300;
}

.red3 {
	color:#752929;
}

.red3-back {
	background-color: #752929
}

.yellow {
	color:yellow;
}

.yellow-back {
	background-color:yellow;
}

.yellow2 {
	color:#d7ff4c;
}

.yellow2-back {
	background-color: #d7ff4c;
}

.pink {
	color:#bd6591;
}

.pink-back {
	background-color:#bd6591;
}

.pink2 {
	color:#f63a9b;
}

.pink2-back {
	background-color:#f63a9b;
}

.pink3 {
	color:#ff5353;
}

.pink3-back {
	background-color: #ff5353;
}

.purple {
	color:#c028ff;
}

.purple-back {
	background-color:#303;
}

.purple2 {
	color:#9c64aa;
}

.purple2-back {
	background-color:#9c64aa;
}

.purple3 {
	color:#5f2975;
}

.purple3-back {
	background-color:#5f2975;
}

.purple4 {
	color:#75295e;
}

.purple4-back {
	background-color:#75295e;
}

.teal {
	color:#29756d;
}

.teal-back {
	background-color:#29756d;
}

.brown {
	color:#c3a991;
}

.brown-back {
	background-color:#c3a991;
}

.orange {
	color:#F90;
}

.orange-back {
	background-color:#F90;
}

.orange2 {
	color:#755929;
}

.orange2-back {
	background-color:#755929;
}

.beige {
	color:#fbb44f;
}

.beige-back {
    background-color:#fbb44f;
}

.transparent {
	color:transparent;
}

.transparent-back {
	background:transparent;
}


/*
Color Scheme 1

color

*/


.color1 {
	color:#814c4c;
}

.color2 {
	color:#64aa66;
}

.color3 {
	color:#51518a;
}

.color4 {
	color:#5c3860;
}

.color5 {
	color:#365c43;
}

.color6 {
	color:#a0a15f;
}

.color7 {
	color:#3e8acc;
}


/*

Color Scheme 1

background color

*/


.color1-back {
	background-color: #814c4c;
}

.color2-back {
	background-color: #64aa66;
}

.color3-back {
	background-color: #51518a;
}

.color4-back {
	background-color: #5c3860;
}

.color5-back {
	background-color: #365c43;
}

.color6-back {
	background-color: #a0a15f;
}

.color7-back {
	background-color:#3e8acc;
}



/*

Color Scheme 2

color

*/



.color-2-1 {
	color:#510000;
}

.color-2-2 {
	color:#002c55;
}

.color-2-3 {
	color:#135100;
}

.color-2-4 {
	color:#510026;
}

.color-2-5 {
	color:#004f5d;
}



/*

Color Scheme 2

background color

*/



.color-2-1-back {
	background-color:#510000;
}

.color-2-2-back {
	background-color:#002c55;
}

.color-2-3-back {
	background-color:#135100;
}

.color-2-4-back {
	background-color:#510026;
}

.color-2-5-back {
	background-color:#004f5d;
}




/* ********************************************  SCROLL  ********************************************  */


.cutoff {
	overflow:hidden;
}

.cutoff-x {
	overflow-x:hidden;
}

.cutoff-y {
	overflow-y:hidden;
}

.scroll {
	overflow:scroll;
}

.scroll-x {
	overflow-x:scroll;
}

.scroll-y {
	overflow-y:scroll;
}

.hidescroll::-webkit-scrollbar {
    display: none;
}



/* wide dark vertical */

.scroll-y-style-1::-webkit-scrollbar-track {
    background-color: white;
}

.scroll-y-style-1::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scroll-y-style-1::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #333;
}



/* wide dark Horizontal */

.scroll-x-style-1::-webkit-scrollbar-track {
    background-color: white;
}

.scroll-x-style-1::-webkit-scrollbar {
    height: 12px;
    background-color: #555;
}

.scroll-x-style-1::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}



/* Dark Small Vertical */

.scroll-y-style-2::-webkit-scrollbar-track {
	background-color:white;
}

.scroll-y-style-2::-webkit-scrollbar {
    width: 6px;
    background-color:#555;
}

.scroll-y-style-2::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}



/* Dark small horizontal */


.scroll-x-style-2::-webkit-scrollbar-track {
	background-color:white;
}

.scroll-x-style-2::-webkit-scrollbar {
    height: 6px;
    background-color:#555;
}

.scroll-x-style-2::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}



/* wide white vertical */

.scroll-y-style-white-1::-webkit-scrollbar-track {
	background-color:#555;
}

.scroll-y-style-white-1::-webkit-scrollbar {
    width: 12px;
    background-color:#ddd;
}

.scroll-y-style-white-1::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}



/* wide white horizontal */

.scroll-x-style-white-1::-webkit-scrollbar-track {
	background-color:#555;
}

.scroll-x-style-white-1::-webkit-scrollbar {
    height: 12px;
    background-color:#ddd;
}

.scroll-x-style-white-1::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}


.scroll-1-height::-webkit-scrollbar {
	height:12px;
}


.scroll-2-height::-webkit-scrollbar {
	height:6px;
}




$y: "vertical";
$x: "horizontal";
$dark: "dark";
$light: "light";
$wide: "wide";
$narrow: "narrow";
$scroll-dark-thumb: #333;
$scroll-dark-track: #EEE;
$scroll-light-thumb: #EEE;
$scroll-light-track: #333;
$scroll-wide: 12px;
$scroll-wide-border-radius: 6px;
$scroll-wide-shadow-inset: 2px;
$scroll-narrow: 6px;
$scroll-narrow-border-radius: 3px;
$scroll-narrow-shadow-inset: 1px;
$scroll-inset-opacity: 0.3;
$directions: (
	vertical:$y,
	horizontal:$x
);
$colors: (
	dark:(
		color:$dark,
		thumb:$scroll-dark-thumb,
		track:$scroll-dark-track
	),
	light:(
		color:$light,
		thumb:$scroll-light-thumb,
		track:$scroll-light-track
	)
);
$sizes: (
	wide: (
		size:$wide,
		width:$scroll-wide,
		height:$scroll-wide,
		border-radius:$scroll-wide-border-radius,
		inset:$scroll-wide-shadow-inset
	),
	narrow: (
		size:$narrow,
		width:$scroll-narrow,
		height:$scroll-narrow,
		border-radius:$scroll-narrow-border-radius,
		inset:$scroll-narrow-shadow-inset
	)
);




@mixin box-shadow-mixin($size) {
	@if $size == $wide {
    	box-shadow: inset 0 0 $scroll-wide-shadow-inset rgba(0,0,0, $scroll-inset-opacity);
    } @else {
    	box-shadow: inset 0 0 $scroll-narrow-shadow-inset rgba(0,0,0, $scroll-inset-opacity);
    }
}

@mixin track-color($color) {
	@if $color == $dark {
		background-color: $scroll-dark-track;
	} @else {
		background-color: $scroll-light-track;
	}
}

@mixin thumb-color($color) {
	@if $color == $dark {
		background-color: $scroll-dark-thumb;
	} @else {
		background-color: $scroll-light-thumb;
	}

}

@mixin thumb-size($size) {
	@if  $size == $wide {
    	border-radius: $scroll-wide-border-radius;
	} @else {
    	border-radius: $scroll-narrow-border-radius;
	}

	@include box-shadow-mixin($size);

}



@mixin scrollbar($direction, $size) {
	@if $size == $wide {
		@if $direction == $y {
			width: $scroll-wide
		} @else {
			height: $scroll-wide;
		}

	} @else {
		@if $direction == $y {
			width: $scroll-narrow
		} @else {
			height: $scroll-narrow;
		}
	}
}






@each $dirName, $dir in $directions {
	@each $colorName, $color in $colors {
		@each $sizeName, $size in $sizes {
			/* ______________________________________________________

				#{$dirName}, #{$colorName}, #{$sizeName}

			*/

			.scroll-#{$dirName}-#{$colorName}-#{$sizeName}::-webkit-scrollbar-track {
				@include track-color(#{$colorName});
			}


			.scroll-#{$dirName}-#{$colorName}-#{$sizeName}::-webkit-scrollbar {
				@include thumb-color(#{$colorName});
				@include scrollbar(#{$dirName}, #{$sizeName});
			}


			.scroll-#{$dirName}-#{$colorName}-#{$sizeName}::-webkit-scrollbar-thumb {
				@include thumb-size(#{$sizeName});
				@include thumb-color(#{$colorName});
			}


			/* _______________________________________________________ */

		}
	}


}









.touch {
	-webkit-overflow-scrolling: touch;
}






/* ********************************************  CENTERING  ********************************************  */

.text-center {
    text-align:center;
}

.text-right {
	text-align:right;
}

.cell-center {
	vertical-align:middle;
    text-align:center;
}

.cell-top {
	vertical-align:top;
	text-align:top;
}

.cell-bottom {
	vertical-align:bottom;
	text-align:bottom;
}

.golden {
	top: 30%;
	transform: translate(0, -30%);
}

.hcenter {
    left: 50%;
    margin-right: -50%;
	transform: translate(-50%, 0);
}

.vcenter {
	top: 50%;
	transform: translate(0, -50%);
}


.center {
	top:50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); /* IE 9 */
}

/* ********************************************  ROTATE  ********************************************  */

.flip-h {
	transform: scaleX(-1);
    filter: fliph; /* IE */
}

.flip-v {
	transform: scaleY(-1);
    filter: flipv; /* IE */
}

.rotate-counter-90 {
	transform: rotate(-90deg);
}

.rotate-clock-90 {
	transform: rotate(90deg);
}




.height-auto {
	height:auto;
}

.height {
	height:100%;
}








$i: 0;


@while $i <= 95 {


	.height#{$i} {
		height:#{$i + "%"};
	}

	$i: $i + 5;
}



$i: 110;


@while $i <= 200 {


	.height#{$i} {
		height:#{$i + "%"};
	}

	$i: $i + 10;
}




$i: 300;


@while $i <= 600 {


	.height#{$i} {
		height:#{$i + "%"};
	}

	$i: $i + 100;
}




$i: 0;


@while $i < 5 {


	.height-#{$i} {
		height:#{$i}px;
	}

	$i: $i + 1;
}



$i: 0;


@while $i <= 100 {


	.height-#{$i} {
		height:#{$i}px;
	}

	$i: $i + 5;
}




$i: 110;


@while $i <= 300 {


	.height-#{$i} {
		height:#{$i}px;
	}

	$i: $i + 10;
}




$i: 400;


@while $i <= 1000 {


	.height-#{$i} {
		height:#{$i}px;
	}

	$i: $i + 100;
}






$i: 1200;


@while $i <= 2400 {


	.height-#{$i} {
		height:#{$i}px;
	}

	$i: $i + 200;
}





.min-height100 {
	min-height:100%;
}






$i: 200;


@while $i <= 2000 {


	.min-height-#{$i} {
		min-height:#{$i}px;
	}

	$i: $i + 200;
}









.width-auto {
	width:auto;
}

.width {
	width:100%;
}






$i: 0;


@while $i <= 95 {


	.width#{$i} {
		width:#{$i + "%"};
	}

	$i: $i + 5;
}



$i: 110;


@while $i <= 200 {


	.width#{$i} {
		width:#{$i + "%"};
	}

	$i: $i + 10;
}




$i: 300;


@while $i <= 600 {


	.width#{$i} {
		width:#{$i + "%"};
	}

	$i: $i + 100;
}




$i: 0;


@while $i < 5 {


	.width-#{$i} {
		width:#{$i}px;
	}

	$i: $i + 1;
}



$i: 5;


@while $i <= 100 {


	.width-#{$i} {
		width:#{$i}px;
	}

	$i: $i + 5;
}




$i: 110;


@while $i <= 300 {


	.width-#{$i} {
		width:#{$i}px;
	}

	$i: $i + 10;
}




$i: 400;


@while $i <= 1000 {


	.width-#{$i} {
		width:#{$i}px;
	}

	$i: $i + 100;
}






$i: 1200;


@while $i <= 2400 {


	.width-#{$i} {
		width:#{$i}px;
	}

	$i: $i + 200;
}





.min-width100 {
	min-width:100%;
}






$i: 200;


@while $i <= 2000 {


	.min-width-#{$i} {
		min-width:#{$i}px;
	}

	$i: $i + 200;
}







.z-5 {
	z-index:5;
}





$i: 10;


@while $i <= 100 {


	.z-#{$i} {
		z-index:#{$i};
	}

	$i: $i + 10;
}



$i: 200;


@while $i <= 500 {


	.z-#{$i} {
		z-index:#{$i};
	}

	$i: $i + 100;
}



$i: -100;


@while $i <= -10 {


	.z-minus-#{abs($i)} {
		z-index:#{$i};
	}

	$i: $i + 10;
}
