.console {
    position: absolute;
    height: 50%;
    width: 100%;
    border: 1px solid black;
    padding: 0;
    margin: 0;
    bottom: 0;
    overflow: hidden; 
}

.console-inner {
    position: absolute;
    width: 90%;
    left: 10%;
    height: auto;
    bottom:0;
}

.item {
    position: relative;
    height: auto;
    width: 100%;
}