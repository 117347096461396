html, body {
	height:100%;
	width:100%;
	margin:0;
	padding:0;
}


.linkbtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;

  .container  {
    position:relative;
    width: 80%;
    height: 100px;
    border-radius: 20px;
    margin: 20px 0;
    cursor: pointer;
  }
  
  .inner {
    position: absolute;
    left:50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: white;
    font-size: 40px;
  }
}

.settingsbtn {
  position: absolute;
  top: 100px;
  right: 0;
  margin: 20px;
  font-size: 70px;
}

.arena {
  position: absolute;
  width: 95%;
  height: 95%;
}

.object {
  width: 100px;
  height: 100px;
  background-color: black;
  border-radius: 50%;
}

.btn {
  position: relative;
  width: 100px;
  height: 50px;
  margin: 20px;
  color: white;
  border-radius: 20px;
  user-select: none;
  cursor: pointer;

  span {
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
  }
}